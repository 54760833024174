import $ from 'jquery';
import 'slick-carousel/slick/slick';

$(function () {
    /**
     * SLIDER PARAMS.
     * Le responsive utilisé doit être celui intégrer au module 'slick'.
     */
    setTimeout(function () {
        $('.autoplay').slick({
            slidesToShow: 4,
            slidesToScroll: 1,
            autoplay: true,
            autoplaySpeed: 2000,
            responsive: [{
                    breakpoint: 1200,
                    settings: {
                        slidesToShow: 3,
                        slidesToScroll: 1,
                        autoplaySpeed: 1250,
                    },
                },
                {
                    breakpoint: 768,
                    settings: {
                        slidesToShow: 2,
                        slidesToScroll: 2,
                        autoplaySpeed: 1000,
                    },
                },
            ],
        });

        /**
         * Hover figcaptions.
         */
        const elements = document.querySelectorAll('.element');
        for (let i = 0; i < elements.length; i++) {
            $(elements[i]).on("mouseenter", function () {
                $(this.lastElementChild).stop().slideToggle("slow").css({
                    'display': "flex"
                });
            });
            $(elements[i]).on("mouseleave", function () {
                $(this.lastElementChild).stop().slideToggle("slow");
            });
        }

        /**
         * Inversion des class "prev" et "next" pour avoir le sens voulu au clic sur les flèches du slider.
         */
        const btnPrev = $('body#accueil > main.container > section > section.autoplay > button:first-of-type');
        const btnNext = $('body#accueil > main.container > section > section.autoplay > button:last-of-type');
        $(btnPrev).removeClass('slick-prev').addClass('slick-next');
        $(btnNext).removeClass('slick-next').addClass('slick-prev');
    }, 0);

});
